import "./Closed.scss";
import MainLayout from "../../layouts/MainLayout";



export const Closed = () => {
  

  return (
    <MainLayout className="register-page">
      <div className="participated-form">
        <p className="scan-text">Thank you for your interest, this contest is now closed</p>
      </div>
      
    </MainLayout>
  );
};
export default Closed;
